<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card mt-2 px-2">
        <div class="card-body">
          <span class="font-weight-bold">
            <i class="fa fa-line-chart mr-2"></i> Log Activity
          </span>
          <h4 class="pt-1 mt-1">All Log Activity</h4>
        </div>
      </div>
      <div class="card">
        <br />
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row justify-content-between">
            <div class="col-md-4">
              <input
                v-model="keyword"
                type="text"
                class="form-control"
                placeholder="search data request, data response, email..."
                @keyup.enter="filter()"
              />
            </div>
            <div class="col-md-5">
              <div>
                <b-dropdown block id="dropdown-1" variant="light">
                  <template #button-content>
                    <i class="fa fa-filter"></i> Filter
                  </template>
                  <div class="m-2">
                    <label class="mb-1">Data URL</label>
                    <input
                      v-model="dataUrl"
                      type="text"
                      class="form-control"
                      placeholder="search data url / endpoint"
                    />
                  </div>
                  <div class="m-2">
                    <label>Method</label>
                    <multiselect
                      v-model="method"
                      label="text"
                      track-by="text"
                      placeholder="select method"
                      open-direction="bottom"
                      :options="methodOption"
                      :searchable="true"
                      multiple
                    >
                      <span slot="noResult">
                        Oops! No elements found. Consider changing the search
                        query.
                      </span>
                    </multiselect>
                  </div>
                  <div class="form-inline m-2">
                    <label class="mb-1">Created Date</label>
                    <v-date-picker
                      v-model="range"
                      mode="date"
                      :masks="masks"
                      is-range
                    >
                      <template
                        v-slot="{ inputValue, inputEvents, isDragging }"
                      >
                        <input
                          id="startPeriod"
                          class="form-control mr-2"
                          :class="
                            isDragging ? 'text-gray-600' : 'text-gray-900'
                          "
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                        />
                        <input
                          id="endPeriod"
                          class="form-control"
                          :class="
                            isDragging ? 'text-gray-600' : 'text-gray-900'
                          "
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          :max-date="new Date()"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <br />
                  <button class="btn btn-primary m-2" @click="filter()">
                    <i class="fa fa-filter"></i> Filter
                  </button>
                  <button class="btn btn-light m-2" @click="reset()">
                    Reset
                  </button>
                </b-dropdown>
              </div>
            </div>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
            head-variant="light"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(email)="row">
              <div style="width: 200px">
                {{ row.item?.admin_cms ? row.item?.admin_cms?.email : '-' }}
              </div>
            </template>

            <template #cell(data_url)="row">
              <div style="width: 200px">
                {{ row.item?.data_url }}
              </div>
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
              </div>
            </template>

            <template #cell(action)="row">
              <router-link
                :to="{ path: 'log/' + row.item?.id }"
                target="”_blank”"
              >
                <i class="fa fa-eye"></i
              ></router-link>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-between">
          <span class="mx-5">
            Showing <span class="font-weight-bold">{{ startIndex }}</span> to
            <span class="font-weight-bold">{{ endIndex }}</span> of
            <span class="font-weight-bold">{{ totalRows }}</span> entries
          </span>
          <b-pagination
            class="mr-5"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
const methods = [
  {
    text: 'POST',
    value: 'POST',
  },
  {
    text: 'PUT',
    value: 'PUT',
  },
  {
    text: 'GET',
    value: 'GET',
  },
  {
    text: 'DELETE',
    value: 'DELETE',
  },
  {
    text: 'PATCH',
    value: 'PATCH',
  },
];
export default {
  name: 'MonitoringLog',
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'email', label: 'Email' },
        { key: 'data_url', label: 'DATA URL' },
        { key: 'host_client', label: 'HOST CLIENT' },
        { key: 'method', label: 'METHOD' },
        { key: 'created', label: 'CREATED' },
        { key: 'action', label: '' },
      ],
      keyword: null,
      methodOption: [...methods],
      dataUrl: null,
      method: null,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    };
  },
  watch: {
    currentPage: function() {
      this.getLog();
    },
    startIndex: function() {
      return 1;
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Monitoring Log | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.log.isLoading,
      isError: (state) => state.log.isError,
      totalRows: (state) => state.log.totalRows,
      items: (state) => state.log.items,
    }),
    startIndex: function() {
      const start = (this.currentPage - 1) * this.perPage + 1;
      return start;
    },
    endIndex: function() {
      return this.startIndex + this.perPage - 1;
    },
  },
  mounted() {
    this.getLog();
  },
  methods: {
    ...mapActions('log', ['fetchLog']),

    getLog() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.method?.length)
        payload.method = this.method.map((val) => val?.value?.toUpperCase());
      if (this.keyword) payload.keyword = this.keyword;
      if (this.dataUrl) payload.dataUrl = this.dataUrl;
      if (this.range?.start)
        payload.startDate = moment(this.range?.start).format('YYYY-MM-DD');
      if (this.range?.end)
        payload.endDate = moment(this.range?.end).format('YYYY-MM-DD');
      this.fetchLog(payload);
      this.setQuery(payload);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    filter() {
      if (
        (this.range?.start && !this.range?.end) ||
        (!this.range?.start && this.range?.end)
      ) {
        this.alertMsg('please select start date and end date', 'warning');
        return;
      }
      this.currentPage = 1;
      let payload = {
        page: 1,
        limit: this.perPage,
      };
      if (this.method?.length)
        payload.method = this.method.map((val) => val?.value?.toUpperCase());
      if (this.keyword) payload.keyword = this.keyword;
      if (this.dataUrl) payload.dataUrl = this.dataUrl;
      if (this.range?.start)
        payload.startDate = moment(this.range?.start).format('YYYY-MM-DD');
      if (this.range?.end)
        payload.endDate = moment(this.range?.end).format('YYYY-MM-DD');
      this.fetchLog(payload);
      this.setQuery(payload);
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    reset() {
      this.range = null;
      this.method = null;
      this.dataUrl = null;
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
<style scoped>
.header-table {
  background-color: darkgrey;
}
</style>
